const getDefaultState = {
  url: process.env.NODE_ENV === 'development'
    ? 'http://192.168.0.27:6543/' // 'http://192.168.61.232:6543/' 'http://192.168.61.232:6543/' 'http://192.168.0.49:6543/' // 'http://TC-104625.GLOBAL-STAFF.LOCAL:6543/' // 'http://192.168.0.49:6543/' // 'http://192.168.0.49:6543/' // 'http://192.168.0.29:6543/' // 'http://192.168.0.49:6543/' // 'http://192.168.0.48:6543/' // 'http://10.0.0.230:6543/' // 'http://10.0.0.8:6543/' // 'http://92.242.36.86:6543/'
    : window.location.protocol + '//' + window.location.host + '/api/',
  // : 'http://192.168.0.48:6543/',
  pdfGenURL: process.env.VUE_APP_PDFGENURL ? process.env.VUE_APP_PDFGENURL : window.location.protocol + '//' + window.location.host + '/generate',
  preventNext: false,
  barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
  barImage: require('../assets/sidebar-1.jpg'),
  vers: 'v:' + process.env.VUE_APP_VERSION.slice(0, -1) + process.env.VUE_APP_VERSIONKEY,
  dev: process.env.NODE_ENV === 'development',
  ticketsType: '?Action=CustomerTicketOverview;Subaction=MyTickets',
  exp_note: [
    { id: 1, name: 'Встреча с клиентом' },
    { id: 2, name: 'День отдыха за ранее отработанное время' },
    { id: 3, name: 'Командировка в другой город' },
    // { id: 4, name: 'Ошибка программы' },
    { id: 5, name: 'Поездка на другой объект' },
    { id: 6, name: 'Пропуск отсутствует' },
    { id: 7, name: 'Работа из дома' },
    { id: 8, name: 'Сотрудник уволен' },
    { id: 9, name: 'Тренинг' },
    { id: 10, name: 'Другое' },
  ],
  lastRoute: '',
  foremanFIO: {
    fioshort: '',
  },
  snackbar: {
    time: 18000,
    state: false,
    color: 'success',
    text: null,
    direction: 'top center',
  },
  snackbarAutoSum: {
    state: false,
    text: '',
  },
  recaptcha: false,
  token: sessionStorage.getItem('token') || '',
  AuthErrorMsg: '',
  loading: false,
  jitsiScriptLoaded: false,
  conferenceIsActive: false,
  builderTemplatePanelSettingsOpen: true,
  empid: sessionStorage.getItem('empid') || '',
  agent: '',
  drawer: null,
  alerts: [{ status: false, message: '', type: 'info' }],
  EmpListString: '',
  EmpDept: null,
  empList: Array(0),
  VacDate: Array(0),
  selectedEmp: Array(0),
  events: Array(0),
  employees: Array(0),
  VacEmployees: Array(0),
  isAuthenticated: false,
  timeSheetsStates: Array(0),
  SPFtimeSheetsStates: Array(0),
  calendarStart: new Date().toISOString().substr(0, 10),
  selected: false,
  dateFrom: new Date().toISOString().substr(0, 10),
  dateTo: new Date().toISOString().substr(0, 10),
  alert: {
    showAlert: false,
    infoTitle: '',
    infoText: '',
    wAgree: false,
  },
  monthNames: ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня',
  'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'],
  showAddCagent: false,
  DossiersOffices: [],
  objects: {},
  selectetObject: '',
  objectsList: {},
  dataLoading: false,
  // ---planing styles
  tableStyles: null,
  plTablesChanges: {},
  plTablesData: {},
  plTableState: {
    selectedCell: null,
    chosenCell: null,
  },
  tableFilters: {
    rows: {},
    cols: {},
    mainCol: {},
  },
  cssLoad: false,
  cssStyles: null,
  dossiersOfficesFilter: null,
  openAddChartModal: {
    open: false,
    tableId: null,
    updateTableById: false,
  },
  openAddTableModal: false,
  informationAboutTable: {},
  dateFromListPostings: '',
  dateTillListPostings: '',
  flgviewallListPostings: '',
  cellFormula: {
    show: false,
    data: null,
  },
}

export default () => ({
  ...JSON.parse(JSON.stringify(getDefaultState)),
})
