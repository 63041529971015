export default {
  tableStyles: state => {
    return state.tableStyles
  },
  url: state => {
    return state.url
  },
  fio: state => {
    return state.foremanFIO.fioshort
  },
  auth: state => {
    return state.auth
  },
  vers: state => {
    return state.vers
  },
  token: state => {
    return state.token
  },
  empid: state => {
    return state.empid
  },
  lastRoute: state => {
    return state.lastRoute
  },
  dateFrom: state => {
    return state.dateFrom
  },
  dateTo: state => {
    return state.dateTo
  },
  selectedEmp: state => {
    return state.selectedEmp
  },
  drawer: state => {
    return state.drawer
  },
  orders: state => {
    return state.orders
  },
  employees: state => {
    return state.employees
  },
  VacEmployees: state => {
    return state.VacEmployees
  },
  VacDate: state => {
    return state.VacDate
  },
  monthNames: state => {
    return state.monthNames
  },
  Departuries: state => {
    return state.Departuries
  },
  emplAvatar: state => {
    return state.emplAvatar
  },
  imgLoading: state => {
    return state.imgLoading
  },
  selectedOrder: state => {
    return state.selectedOrder
  },
  selectedEmpl: state => {
    return state.selectedEmpl
  },
  empToCopy: state => {
    return state.empToCopy
  },
  alerts: state => {
    return state.alerts
  },
  alert: state => {
    return state.alert
  },
  exp_note: state => {
    return state.exp_note
  },
  EmpDept: state => {
    return state.EmpDept
  },
  selected: state => {
    return state.selected
  },
  tabsItems: state => {
    return state.tabsItems
  },
  foremanFIO: state => {
    return state.foremanFIO
  },
  isAuthenticated: state => {
    return state.isAuthenticated
  },
  loading: state => {
    return state.loading
  },
  jitsiScriptLoaded: state => {
    return state.jitsiScriptLoaded
  },
  conferenceIsActive: state => {
    return state.conferenceIsActive
  },
  builderTemplatePanelSettingsOpen: state => {
    return state.builderTemplatePanelSettingsOpen
  },
  dataLoading: state => {
    return state.dataLoading
  },
  timeSheetsStates: state => {
    return state.timeSheetsStates
  },
  SPFtimeSheetsStates: state => {
    return state.SPFtimeSheetsStates
  },
  objects: state => {
    return state.objects
  },
  selectetObject: state => {
    return state.selectetObject
  },
  getObjectsList: state => {
    return state.objectsList
  },
  EmpListString: state => {
    return state.EmpListString
  },
  empList: state => {
    return state.empList
  },
  events: state => {
    return state.events
  },
  calendarStart: state => {
    return state.calendarStart
  },
  showAddCagent: state => {
    return state.showAddCagent
  },
  DossiersOffices: state => {
    return state.DossiersOffices
  },
  plTablesChanges: state => {
    return state.plTablesChanges
  },
  selectedCell: state => {
    return state.plTableState.selectedCell
  },
  chosenCell: state => {
    return state.plTableState.chosenCell
  },
  tableFilters: state => {
    return state.tableFilters
  },
  cssLoad: state => {
    return state.cssLoad
  },
  DossiersOfficesFilter: state => {
    return state.dossiersOfficesFilter
  },
}
