import axios from 'axios'
import cookie from 'vue-cookies'

// import router from '../router'
// import qs from 'qs'
axios.defaults.headers.common.tolowercase = 1

export default {
  // auth --->>
  checkHashAuth ({ commit, state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `v2/Authorize/Userhash/${payload.id}/${payload.hash}`,
        withCredentials: false,
      }).then((response) => {
        // console.log('JSON: ' + JSON.stringify(response.data))
        const arr = response.data
        // console.log(arr)
        if (arr.status > 0) {
          if (arr.empid) {
            sessionStorage.setItem('empid', arr.empid)
            sessionStorage.setItem('token', arr.authtoken)
            axios.defaults.headers.common.authtoken = arr.authtoken
            axios.defaults.headers.common.empid = arr.empid
            dispatch('setIsAuthenticated', true)
            dispatch('setToken', arr.authtoken)
            resolve(response)
          } else { reject(arr) }
        } else {
          console.log(response.data)
          reject(arr)
        }
      }).catch((e) => {
        console.log('Error:', e)
        reject(e)
      })
    })
  },
  checkAuth ({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      const token = sessionStorage.token || localStorage.token || cookie.get('token')
      const empid = sessionStorage.empid || localStorage.empid || cookie.get('empid')
      if ((!!token && !!empid)) {
        axios.defaults.headers.common.authtoken = token
        axios.defaults.headers.common.empid = empid
        dispatch('setToken', token)
        dispatch('setEmpid', empid)
        axios({
          method: 'get',
          url: state.url + 'Portal/User/Info',
          withCredentials: false,
        }).then((response) => {
          if (response.data.emptype >= 0) {
            commit('setForemanFIO', response.data)
            resolve(response)
          } else {
            console.log('get catch err EmpType')
            dispatch('authLogout')
            reject(new Error(response.data.message ? response.data.message : 'Вы не офисный сотрудник'))
          }
        }).catch((error) => {
          console.log('get catch err')
          dispatch('authLogout')
          reject(new Error(error))
        })
      } else {
        dispatch('authLogout')
        dispatch('setLoading', false)
        dispatch('setIsAuthenticated', false)
        reject(new Error('Неправильные учетные данные'))
      }
    })
  },
  ldap_auth ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.set('password', value.pass)
      formData.set('username', value.login)
      formData.set('domain', value.domain)
      axios({
        method: 'POST',
        url: state.url + 'v2/Authorize/LDAP',
        // withCredentials: false,
        data: formData,
        headers: {
          password: value.pass,
          username: value.login,
          domain: value.domain,
        },
      }).then((response) => {
        // console.log('JSON: ' + JSON.stringify(response.data))
        var arr = response.data
        // console.log(arr)
        if (arr.status > 0) {
          if (arr.empid) {
              if (value.rememberMe) {
                localStorage.setItem('empid', arr.empid)
                localStorage.setItem('token', arr.authtoken)
              } else {
                sessionStorage.setItem('empid', arr.empid)
                sessionStorage.setItem('token', arr.authtoken)
              }
              axios.defaults.headers.common.authtoken = arr.authtoken
              axios.defaults.headers.common.empid = arr.empid
              dispatch('setIsAuthenticated', true)
              dispatch('setToken', arr.authtoken)
              resolve(response)
          } else { reject(arr) }
        } else {
          console.log(response.data)
          reject(arr)
        }
      }).catch((e) => {
        console.log('Error:', e)
        reject(e)
      })
    })
  },
  authLogout ({ commit, dispatch }) {
    return new Promise((resolve) => {
      dispatch('setIsAuthenticated', false)
      localStorage.removeItem('token')
      sessionStorage.removeItem('token')
      localStorage.removeItem('empid')
      sessionStorage.removeItem('empid')
      delete axios.defaults.headers.common.authtoken
      delete axios.defaults.headers.common.empid
      commit('resetState')
      setTimeout(() => { resolve() }, 2000)
    })
  },
  setLastRoute ({ commit, dispatch }, value) {
      commit('setLastRoute', value)
  },
  // --->> auth

  // timex --->>
  getEmpl ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      // console.log(axios.defaults.headers.common)
      // if(state.EmpListString)
      var formData = new FormData()
      formData.set('Datefrom', value.dateFrom)
      formData.set('Dateto', value.dateTo)
      axios({
        method: 'post',
        url: state.url + 'TimeSheets/List',
        withCredentials: false,
        data: formData,
      })
        .then((response) => {
          var resp = response.data[0]
          if (resp[0] > 0) {
            state.EmpListString = resp[1]
            state.EmpDept = resp[2]
            resolve(response)
          } else {
            if (response.data.id == -1) {
              dispatch('authLogout')
            }
            reject(new Error('нет прав'))
          }
        }).catch((e) => {
        reject(e)
      })
    })
  },
  SendMail ({ state, commit }, value) {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.set('Datefrom', value.dateFrom)
      formData.set('Dateto', value.dateTo)
      formData.set('foremanFIO', value.foremanFIO)
      formData.set('empid', value.empid)
      formData.set('emlList', value.emlList)
      formData.set('RegionName', value.RegionName)
      axios({
        method: 'post',
        url: state.url + 'TimeSheets/Mistmatch/Explanatory/Mail/Send',
        withCredentials: false,
        data: formData,
      }).then((response) => {
        console.log('успешно')
        resolve(response.data)
      })
    })
  },
  SendEmptyMail ({ state, commit }, value) {
    var mn = ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь']
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.set('expmonth', mn[value.dateFrom.getMonth()])
      formData.set('foremanFIO', value.foremanFIO)
      formData.set('RegionName', value.RegionName)
      axios({
        method: 'post',
        url: state.url + 'TimeSheets/Mistmatch/Explanatory/Mail/Empty/Send',
        withCredentials: false,
        data: formData,
      }).then((response) => {
        console.log('успешно')
        resolve(response.data)
      })
    })
  },
  getTimeSheetStates ({ state, commit }, value) {
    return new Promise((resolve, reject) => {
      if (state.timeSheetsStates.length == 0) {
        axios({
          method: 'get',
          url: state.url + 'TimeSheets/States/List',
          withCredentials: false,
        }).then((response) => {
          commit('setTimeSheetStates', response.data)
          resolve(response.data)
        })
      } else { resolve(state.timeSheetsStates) }
    })
  },
  getEmployeesMistmatchList ({ state, commit }, value) {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.set('Datefrom', value.Datefrom)
      formData.set('Dateto', value.Dateto)
      formData.set('empList', value.empListString)
      formData.set('EmpDept', value.EmpDept)
      axios({
        method: 'post',
        url: state.url + 'TimeSheets/Mistmatch/List',
        withCredentials: false,
        data: formData,
      }).then((response) => {
        commit('setEmployees', response.data)
        resolve(response.data)
      })
    })
  },
  getEmpList ({ state, commit }, value) {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.set('Datefrom', value.dateFrom)
      formData.set('Dateto', value.dateTo)
      formData.set('findEmpid', value.findEmpid)
      formData.set('EmpDept', value.EmpDept)
      axios({
        method: 'post',
        url: state.url + 'TimeSheets/Mistmatch/ByDay/List',
        withCredentials: false,
        data: formData,
      }).then((response) => {
        commit('setEmpList', response.data)
        resolve(response.data)
      })
    })
  },
  UpdateNote ({ state, commit }, value) {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.set('DateStr', value.DateStr)
      formData.set('noteid', value.noteid)
      formData.set('note', value.note)
      formData.set('empid', value.empid)
      axios({
        method: 'post',
        url: state.url + 'TimeSheets/Mistmatch/Explanatory/Add',
        withCredentials: false,
        data: formData,
      }).then((response) => {
        if (response.data == 0) {
          console.log('успешно')
          resolve(response.data)
        } else {
          resolve('Ошибка')
        }
      })
    })
  },
  // --->> timex

  // Simplefoods --->>
  getSPFTimeSheetStates ({ state, commit }, value) {
    return new Promise((resolve, reject) => {
      if (state.timeSheetsStates.length == 0) {
        axios({
          method: 'get',
          url: state.url + 'SPF/GetTimesheetStates',
          withCredentials: false,
        }).then((response) => {
          commit('setTimeSheetStates', response.data)
          resolve(response.data)
        })
      } else { resolve(state.timeSheetsStates) }
    })
  },

  getSPFEmlp ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      // console.log(axios.defaults.headers.common)
      const formData = new FormData()
      formData.append('Datefrom', value.dateFrom)
      formData.append('Dateto', value.dateTo)
      // require('axios-debug-log')
      axios({
        method: 'post',
        url: state.url + 'SPF/Employees/List',
        withCredentials: false,
        data: formData,
      })
        .then((response) => {
          var resp = response.data
          if (Array.isArray(resp)) {
            commit('setVacEmployees', resp)
            resolve(response)
          } else {
            reject(resp)
          }
        }).catch((e) => {
        console.log('ошибка')
        reject(e)
      })
    })
  },
  getTally ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      // console.log(axios.defaults.headers.common)
      // axios.defaults.headers.common['AuthToken'] = '121'
      var formData = new FormData()
      formData.set('Datefrom', value.dateFrom)
      formData.set('Dateto', value.dateTo)
      axios({
        method: 'post',
        url: state.url + 'SPF/Tallysheets/List',
        withCredentials: false,
        data: formData,
      })
        .then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  getSummTally ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.set('datefrom', value.dateFrom)
      formData.set('dateend', value.dateTo)
      formData.set('dossiersofficeid', value.dossiersofficeid)
      axios({
        method: 'post',
        url: state.url + 'SPF/Tallysheets/Summary/Get',
        withCredentials: false,
        data: formData,
      })
        .then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  TallyStatusChange ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      // console.log(value)
      // axios.defaults.headers.common['AuthToken'] = '121'
      var formData = new FormData()
      formData.set('SpfTsID', value.spftsid)
      formData.set('NewStatus', value.newstatus)
      axios({
        method: 'post',
        url: state.url + 'SPF/Tallysheet/Status/Change',
        withCredentials: false,
        data: formData,
      })
        .then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  peformPerDayChange ({ state }, { pload, type }) {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.set('performData', pload)
      formData.set('type', type)
      axios({
        method: 'post',
        url: state.url + 'SPF/Performance/dayset',
        withCredentials: false,
        data: formData,
      })
        .then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  getDossiersOffices ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'SPF/Dossiers/Offices/List',
        withCredentials: false,
      })
        .then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  getTallysheetEmpsList ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      // console.log(axios.defaults.headers.common)
      // axios.defaults.headers.common['AuthToken'] = ''
      var formData = new FormData()
      formData.set('SpfTsID', value.SpfTsID)
      axios({
        method: 'post',
        url: state.url + 'SPF/Tallysheet/Employees/List',
        withCredentials: false,
        data: formData,
      })
        .then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  delTallysheetEmployee ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      // параметры SpfTsID, SpfWsID, EmpID
      // console.log(axios.defaults.headers.common)
      var formData = new FormData()
      formData.set('SpfTsEmpID', value._spftsempid)
      axios({
        method: 'post',
        url: state.url + 'SPF/Tallysheet/Employee/Del',
        withCredentials: false,
        data: formData,
      }).then((response) => {
        if (response.data.result == 0) {
          resolve(response.data)
          commit('delVacation', { vacationid: value.vacationid })
        } else {
          reject(response.data)
        }
      }).catch((e) => {
        reject(e)
      })
    })
  },
  setTallysheetEmployee ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      // console.log(axios.defaults.headers.common)
      console.log(value)
      var formData = new FormData()
      if (value.SpfTsEmpID) {
        formData.set('SpfTsEmpID', value.SpfTsEmpID)
      }
      formData.set('SpfTsID', value.SpfTsID)
      if (value.SpfWsID) {
        formData.set('SpfWsID', value.SpfWsID)
      }
      formData.set('EmpID', value.EmpID)
      if (value.BeginTime) {
        formData.set('BeginTime', value.BeginTime)
      }
      if (value.EndTime) {
        formData.set('EndTime', value.EndTime)
      }
      if (value.Notes) {
        formData.set('Notes', value.Notes)
      }
      if (value.offsethours) {
        formData.set('offsethours', value.offsethours)
      }
      if (value.productivity) {
        formData.set('productivity', value.productivity)
      }
      if (value.fine) {
        formData.set('fine', value.fine)
      }
      formData.set('State', value.State)
      axios({
        method: 'post',
        url: state.url + 'SPF/Tallysheet/Employee/Set',
        withCredentials: false,
        data: formData,
      })
        .then((response) => {
          if (response.data.state >= 0) {
            response.data.state = parseInt(response.data.state)
          }
          if (response.data.spfwsid >= 0) {
            response.data.wsid = response.data.spfwsid
          }
          if (value.index) {
            response.data.index = value.index
          }
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  splitTallysheetEmployee ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      // console.log(axios.defaults.headers.common)
      console.log(value)
      var formData = new FormData()
      if (value.SpfTsEmpID) {
        formData.set('SpfTsEmpID', value.SpfTsEmpID)
      }
      formData.set('SpfTsID', value.SpfTsID)
      if (value.SpfWsID) {
        formData.set('SpfWsID', value.SpfWsID)
      }
      formData.set('EmpID', value.EmpID)
      if (value.BeginTime) {
        formData.set('BeginTime', value.BeginTime)
      }
      if (value.EndTime) {
        formData.set('EndTime', value.EndTime)
      }
      if (value.Notes) {
        formData.set('Notes', value.Notes)
      }
      formData.set('State', value.State)
      axios({
        method: 'post',
        url: state.url + 'SPF/Tallysheet/Employee/Set',
        withCredentials: false,
        data: formData,
      })
        .then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  GetWorkshopsList ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + 'SPF/Workshops/List',
        withCredentials: false,
      })
        .then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  addTally ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      // console.log(axios.defaults.headers.common)
      var formData = new FormData()
      formData.set('autofill', value.autofill)
      formData.set('datedate', value.datedate)
      formData.set('DossiersOfficeID', value.dossierofficeid)
      axios({
        method: 'post',
        url: state.url + 'SPF/Tallysheet/Add',
        withCredentials: false,
        data: formData,
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  setTallyNewStatus ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      // console.log(axios.defaults.headers.common)
      var formData = new FormData()
      formData.set('newstatus', value.newstatus)
      formData.set('spftsid', value.spftsid)
      axios({
        method: 'post',
        url: state.url + 'SPF/Tallysheet/Status/Change',
        withCredentials: false,
        data: formData,
      })
        .then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  getSPFperformance ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.set('datebegin', value.dateFrom)
      formData.set('dateend', value.dateTo)
      formData.set('dossiersofficeid', value.dossierofficeid)
      formData.set('ptype', value.ptype || 2)
      axios({
        method: 'post',
        url: state.url + 'SPF/Performance/List',
        withCredentials: false,
        data: formData,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  // --->> Simplefoods

  // siteAdmin --->>
  deleteImage ({ state }, value) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.set('siteid', value.siteid)
      formData.set('artid', value.artid)
      formData.set('fileid', value.fileid)
      axios({
        method: 'post',
        url: state.url + 'WSA/site/articles/file/del',
        withCredentials: false,
        data: formData,
      })
        .then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  getWSsites ({ state }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + 'WSA/site/list',
        withCredentials: false,
      })
        .then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  getWSarticles ({ state }, { siteid, arttype }) {
    return new Promise((resolve, reject) => {
      const value = arttype ? siteid + '?arttype=' + arttype : siteid
      console.log('arttype', arttype)
      console.log('siteid', siteid)
      console.log('val', value)
      axios({
        method: 'get',
        url: state.url + 'WSA/articles/' + value,
        withCredentials: false,
      })
        .then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  getWSarticle ({ state }, value) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + 'WSA/article/' + value.siteid + '/' + value.artid,
        withCredentials: false,
      })
        .then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  getWSpages ({ state }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + 'WSA/page/list',
        withCredentials: false,
      })
        .then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  BanksList ({ state }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + 'Banks/List',
        withCredentials: false,
      })
        .then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  LegalFormsList ({ state }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + 'LegalForms/List',
        withCredentials: false,
      })
        .then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  TaxSystemsList ({ state }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + 'TaxSystems/List',
        withCredentials: false,
      })
        .then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  TaxRatesList ({ state }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + 'TaxRates/List',
        withCredentials: false,
      })
        .then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  checkINN ({ state }, value) {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.set('inn', value)
      axios({
        method: 'post',
        url: state.url + 'Cagents/Cagent/GetByINN',
        withCredentials: false,
        data: formData,
      }).then((response) => {
        // [Title, LegalFormID, inn, payaccount, BankID, kpp, TaxSystemID, TaxRateID]
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  CagentsCagentAdd ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.set('Title', value.title)
      formData.set('LegalFormID', value.legalformid)
      formData.set('inn', value.inn)
      formData.set('BankID', value.bankid)
      formData.set('kpp', value.kpp)
      formData.set('payaccount', value.payaccount)
      formData.set('TaxSystemID', value.taxsystemid)
      formData.set('TaxRateID', value.taxrateid)
      console.log('formData', FormData)
      axios({
        method: 'post',
        url: state.url + 'Cagents/Cagent/Add',
        withCredentials: false,
        data: formData,
      }).then((response) => {
        // [Title, LegalFormID, inn, payaccount, BankID, kpp, TaxSystemID, TaxRateID]
        if (response.data.cagentid > 0) {
          resolve(response.data.cagentid)
        } else {
          reject(response.data)
        }
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getWScontent ({ state }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + 'WSA/site/content/list',
        withCredentials: false,
      })
        .then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  getWSsections ({ state }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + 'WSA/section/list',
        withCredentials: false,
      })
        .then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  updateWScontent ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.set('siteid', value.site_id)
      formData.set('contentid', value.cont_id)
      formData.set('title', value.content_title)
      formData.set('content', value.content)
      formData.set('orderindex', value.orderindex)
      formData.set('allowed', value.allowed)
      axios({
        method: 'post',
        url: state.url + 'WSA/site/content/set',
        withCredentials: false,
        data: formData,
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  updateWSarticle ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.set('siteid', value.siteid)
      formData.set('pageid', value.page_id)
      formData.set('pgtitle', value.title)
      formData.set('metatitle', value.metatitle)
      formData.set('metatag', value.metatags)
      formData.set('allowed', value.allowed)
      axios({
        method: 'post',
        url: state.url + 'WSA/site/meta/set',
        withCredentials: false,
        data: formData,
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  setWScontent ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.set('siteid', value.site_id)
      formData.set('sectionid', value.sectionid)
      formData.set('title', value.content_title)
      formData.set('content', value.content)
      formData.set('orderindex', value.orderindex || 1)
      formData.set('allowed', value.allowed || 0)
      axios({
        method: 'post',
        url: state.url + 'WSA/site/content/add',
        withCredentials: false,
        data: formData,
      }).then((response) => {
          resolve(response.data[0])
        }).catch((e) => {
        reject(e)
      })
    })
  },
  setContent ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      const articleFilds = Object.keys(value)
      console.log('articleFilds', articleFilds)
      var formData = new FormData()
      articleFilds.forEach((x) => {
        if (!(x == 'route' && !value[x])) {
          formData.set(x, value[x])
        }
      })
      axios({
        method: 'post',
        url: state.url + 'WSA/site/articles/set',
        withCredentials: false,
        data: formData,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  // --->> siteAdmin

  // contracts --->>
  getAreasList ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + 'Areas/List',
        withCredentials: false,
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  getCagentsList ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + 'Cagents/List',
        withCredentials: false,
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  getContractsList ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      if (value.activeonly >= 0) {
        formData.set('activeonly', value.activeonly)
      }
      axios({
        method: 'post',
        url: state.url + 'Contracts/List',
        withCredentials: false,
        data: formData,
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  getContractsServiceList ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      // console.log(axios.defaults.headers.common)
      // axios.defaults.headers.common['AuthToken'] = ''
      axios({
        method: 'get',
        url: state.url + 'Contracts/Service/List',
        withCredentials: false,
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  getContractsTypesList ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      // console.log(axios.defaults.headers.common)
      // axios.defaults.headers.common['AuthToken'] = ''
      axios({
        method: 'get',
        url: state.url + 'Contracts/Types/List',
        withCredentials: false,
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  getContractsServicePriceList ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      // console.log(axios.defaults.headers.common)
      // axios.defaults.headers.common['AuthToken'] = ''
      axios({
        method: 'get',
        url: state.url + 'Contracts/Service/Price/List',
        withCredentials: false,
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  getContractsFileList ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.set('contractid', value.contractid)
      axios({
        method: 'post',
        url: state.url + 'Contracts/File/List',
        withCredentials: false,
        data: formData,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  uploadContractsFiles ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.set('contractid', value.contractid)
      formData.set('filebody', value.filebody)
      axios({
        method: 'post',
        url: state.url + 'Contracts/File/Add',
        withCredentials: false,
        data: formData,
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  importCSVFile ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.set('pltabledataid', value.pltabledataid)
      formData.set('truncate_table', value.truncateTable ? 1 : 0)
      formData.set('delimiter', value.delimiter)
      formData.set('csv_file', value.csvFile)
      axios({
        method: 'post',
        url: state.url + '/Planning/Table/Data/Upload',
        withCredentials: false,
        data: formData,
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  uploadArticleImg ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.set('siteid', value.siteid)
      formData.set('artid', value.artid)
      formData.set('filebody', value.filebody)
      formData.set('flgmainimg', value.flgmainimg)
      axios({
        method: 'post',
        url: state.url + 'WSA/site/articles/file/set',
        withCredentials: false,
        data: formData,
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  getContractFile ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      var formData = new FormData()
      formData.set('contractid', value.contractid)
      formData.set('fileid', value.fileid)
      axios({
        method: 'post',
        url: state.url + 'Contracts/File/Get',
        withCredentials: false,
        responseType: 'blob',
        data: formData,
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  createNewReportVersion ({ state, dispatch, commit }, newVersData) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Version/Add',
        withCredentials: false,
        data: newVersData,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  createNewReportCategory ({ state, dispatch, commit }, newCategoryData) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Category/Add',
        withCredentials: false,
        data: newCategoryData,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  updateReportCategory ({ state, dispatch, commit }, categoryUpdateData) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Category/Update',
        withCredentials: false,
        data: categoryUpdateData,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  createNewReportTemplate ({ state, dispatch, commit }, newTemplateData) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Report/Add',
        withCredentials: false,
        data: newTemplateData,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  createNewReportTab ({ state, dispatch, commit }, newTabData) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Tab/Add',
        withCredentials: false,
        data: newTabData,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  createNewReportTable ({ state, dispatch, commit }, newTableData) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Table/Add',
        withCredentials: false,
        data: newTableData,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  createNewReportTableCol ({ state, dispatch, commit }, newTableCol) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Table/Col/Add',
        withCredentials: false,
        data: newTableCol,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  updateReportTableCol ({ state, dispatch, commit }, updateTableCol) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Table/Col/Update',
        withCredentials: false,
        data: updateTableCol,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  updateReportTableColSortindex ({ state, dispatch, commit }, updateTableDataCols) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Table/Cols/Sortindex/Update',
        withCredentials: false,
        data: updateTableDataCols,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  delReportTableCol ({ state, dispatch, commit }, delTableCol) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Table/Col/Del',
        withCredentials: false,
        data: delTableCol,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  createNewReportTableRow ({ state, dispatch, commit }, newTableRow) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Table/Row/Add',
        withCredentials: false,
        data: newTableRow,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  updateReportTableRow ({ state, dispatch, commit }, updateTableRow) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Table/Row/Update',
        withCredentials: false,
        data: updateTableRow,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  updateReportTableRowSortindex ({ state, dispatch, commit }, updateTableDataRows) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Table/Rows/Sortindex/Update',
        withCredentials: false,
        data: updateTableDataRows,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  delReportTableRow ({ state, dispatch, commit }, delTableRow) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Table/Row/Del',
        withCredentials: false,
        data: delTableRow,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getStylesTableInfo ({ state, dispatch, commit }, pltableid) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `Planning/Table/${pltableid}/Styles/List`,
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  planningTableQueriesInfo ({ state, dispatch, commit }, pltableid) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `Planning/Table/${pltableid}/Queries/List`,
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  updateStylesTableInfo ({ state, dispatch, commit }, updateTableStyles) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Table/Styles/Update',
        withCredentials: false,
        data: updateTableStyles,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getMarkTable ({ state, dispatch, commit }, pltableid) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `Planning/Marks/Data/List/${pltableid}`,
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  addMarksTable ({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Marks/Add',
        withCredentials: false,
        data: payload,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  updateMarkTable ({ state, dispatch, commit }, updateMarkTable) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Table/Marks/Update',
        withCredentials: false,
        data: updateMarkTable,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  delMarkTable ({ state, dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Marks/Del',
        withCredentials: false,
        data: payload,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getPostingDocument ({ state, commit }, value) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + 'Postings/Document/GuidGet?guid=' + value,
        // url: state.url + 'http://92.242.36.86:26543/Postings/Document/Get?id=' + value,
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getPostingFile ({ state, commit }, value) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: `${state.url}Postings/File/GuidGet?guid=${value.guid}&fileid=${value.fileid}`,
        // url: state.url + 'http://92.242.36.86:26543/Postings/File/Get?id=' + value,
        withCredentials: false,
        responseType: 'blob',
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  setTableDataVisible ({ state }, payload) {
    return new Promise((resolve, reject) => {
      const payloadJSON = {
        table_data_id: payload.tableID,
        visible_ids: payload.visibleIDString,
        hide_ids: payload.hideIDString,
      }
      axios({
        method: 'post',
        url: state.url + 'Planning/Tables/Data/Visible/Set',
        withCredentials: false,
        data: payloadJSON,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getTableStyles ({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + 'Planning/Styles/List',
        withCredentials: false,
      }).then((response) => {
        const stylesheet = {}
        response.data.forEach((style) => {
          const styleAsArr = style.style_value.replace(/(\r\n|\n|\r)/gm, '').split(';').filter(o => o)
          const styleAsObj = {}
          styleAsArr.forEach((s) => {
            const [key, value] = s.replace(/\s/g, '').split(':')
            styleAsObj[key] = value
          })
          stylesheet[style.id] = styleAsObj
        })
        commit('setTableStyles', stylesheet)
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getTableStylesAll ({ state }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + 'Planning/Styles/List',
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getTableMarksAll ({ state }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + 'Planning/Marks/List',
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  addTableStyles ({ state, commit }, payload) {
    console.log(payload, 'payload')
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Styles/Add',
        withCredentials: false,
        data: payload,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  delTableStyle ({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Style/Del',
        withCredentials: false,
        data: payload,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getTableData ({ state, dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      let flg = null
      let pltabledataid = null
      if (typeof payload === 'object' &&
        !Array.isArray(payload) &&
        payload !== null) {
        pltabledataid = payload.id
        flg = payload.flg
      } else {
        pltabledataid = payload
      }
      let params = null
      if (flg) {
        params = {
          flgdatavisible: flg,
        }
      }
      axios({
        method: 'get',
        url: state.url + `Planning/Tables/Data/${pltabledataid}/Get`,
        withCredentials: false,
        params: params,
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  getTablesData ({ state, dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      console.log('get tables', payload)
      axios({
        method: 'post',
        url: state.url + 'Planning/Tables/Data/Full',
        withCredentials: false,
        data: {
          tables: payload.tables,
          flgdatavisible: payload.flg || 0,
        },
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  getTablesTempsList ({ state }, plreportid) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `Planning/Tables/List/${plreportid}`,
        withCredentials: false,
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  getTableTemplate ({ state }, pltableid) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `Planning/Tables/${pltableid}/Get`,
        withCredentials: false,
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  getObjectList ({ state, commit }, pllistid) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + `Planning/Object/List/${pllistid}`,
        withCredentials: false,
      }).then((response) => {
          commit('setObjectList', { id: pllistid, data: response.data })
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  delVirtualObject ({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + `Planning/Object/Del/${payload.pllistitemid}`,
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
          reject(e)
      })
    })
  },
  delDynTable ({ state, dispatch }, pltabledataid) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + `/Planning/Tables/Data/Del/${pltabledataid}`,
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
          reject(e)
      })
    })
  },
  addVirtualObject ({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + `Planning/Object/Add/${payload.pllistid}`,
        withCredentials: false,
        data: {
          title: payload.title,
        },
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
          reject(e)
      })
    })
  },
  updateVirtualObject ({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + `Planning/Object/Update/${payload.pllistitemid}`,
        withCredentials: false,
        data: {
          title: payload.title,
        },
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  setAsMainVersion ({ state }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Version/Main/Set',
        withCredentials: false,
        data: payload,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getSnapshotsList ({ state }, pltableid) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `Planning/Tables/${pltableid}/Snapshots/List`,
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getTableSnapshot ({ state }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `Planning/Tables/Data/${payload.pltabledataid}/Snapshot/${payload.plsnapshotid}/Get`,
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  versionTitleSet ({ state }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Version/Title/Set',
        withCredentials: false,
        data: payload,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getPlanningCategories ({ state }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + 'Planning/Categories/List',
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  copyReportVersion ({ state }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Version/Copy',
        withCredentials: false,
        data: payload,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  // todo new method
  getMagicFormula ({ state }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + '/Planning/Cell/Queries/Info/Get',
        withCredentials: false,
        data: payload,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  changeTableChartPattern ({ state }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Chart/Pattern/Set',
        withCredentials: false,
        data: payload,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  setReportVersionStatus ({ state }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Version/Status/Set',
        withCredentials: false,
        data: payload,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getRowPlanningDataList ({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `Planning/Tables/Data/List/${payload.plreportid}/${payload.plversionid}`,
        withCredentials: false,
      }).then((response) => {
        dispatch('setDataLoading', false)
        resolve(response.data)
      }).catch((e) => {
        console.log('e', e)
        reject(e)
      })
    })
  },
  getPlanningVersionListInfo ({ state, dispatch, commit }, plversionid) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `Planning/Lists/${plversionid}/Info/Get`,
        withCredentials: false,
      }).then((response) => {
        dispatch('setDataLoading', false)
        commit('setObjectListInfo', response.data)
        resolve(response.data)
      }).catch((e) => {
        console.log('e', e)
        reject(e)
      })
    })
  },
  getPlanningDataList ({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `Planning/Tables/Data/ByTab/List/${payload.plreportid}/${payload.plversionid}`,
        withCredentials: false,
      }).then((response) => {
        dispatch('setDataLoading', false)
        resolve(response.data)
      }).catch((e) => {
        console.log('e', e)
        reject(e)
      })
    })
  },
  getPlanningReportsList ({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `Planning/Reports/List/${payload.plcategoryid}`,
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        console.log('e', e)
        reject(e)
      })
    })
  },
  updateReportTemplate ({ state, dispatch, commit }, updateTemplateData) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Report/Update',
        withCredentials: false,
        data: updateTemplateData,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getPlanningTabsList ({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `Planning/Tabs/List/${payload.pltemplateid}`,
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        console.log('e', e)
        reject(e)
      })
    })
  },
  updatePlanningTabsList ({ state, dispatch }, updateTabData) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Tab/Update',
        withCredentials: false,
        data: updateTabData,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        console.log('e', e)
        reject(e)
      })
    })
  },
  updatePlaningTabsSortindex ({ state, dispatch, commit }, tabsDataSortindex) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Tabs/Sortindex/Update',
        withCredentials: false,
        data: tabsDataSortindex,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getPlanningTableList ({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `Planning/Table/List/${payload.pltabid}`,
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        console.log('e', e)
        reject(e)
      })
    })
  },
  updatePlanningTableList ({ state, dispatch }, updateTableData) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Table/Update',
        withCredentials: false,
        data: updateTableData,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        console.log('e', e)
        reject(e)
      })
    })
  },
  updatePlanningTableSortindex ({ state, dispatch }, updateTabData) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Table/Sortindex/Update',
        withCredentials: false,
        data: updateTabData,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        console.log('e', e)
        reject(e)
      })
    })
  },
  getPlanningAreasList ({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `Planning/Areas/List/${payload.plreportid}`,
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getPlanningPrList ({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `Planning/presentation/${payload.plreportid}/${payload.plareaid || 'None'}/list`,
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getPrData ({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `Planning/presentation/${payload}/page/list`,
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  savePrPage ({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `/Planning/presentation/${payload.plprid}/page/list`,
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  addPlanningPrList ({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/presentation/Set',
        withCredentials: false,
        data: payload,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  delPresentationPage ({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/presentation/page/Del',
        withCredentials: false,
        data: payload,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  updatePrPageData ({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      payload.pagepattern = JSON.stringify(payload.pagepattern)
      if (payload.template) {
        payload.pagetemplate = JSON.stringify(payload.template)
      }
      payload.template = null
      axios({
        method: 'post',
        url: state.url + 'Planning/presentation/page/Set',
        withCredentials: false,
        data: payload,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  addNewTable ({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + '/Planning/Tables/Data/Add',
        withCredentials: false,
        data: payload,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  delTable ({ state, commit }, pltabledataid) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + `/Planning/Tables/Data/Del/${pltabledataid}`,
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getPlanningVersionsList ({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `Planning/Versions/List/${payload.chosenRep}/${payload.areaid}`,
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getPlanningPr ({ state, dispatch }, prplID) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `Planning/presentation/${prplID}/Get`,
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  setReportVersionActive ({ state }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Version/Active/Set',
        withCredentials: false,
        data: payload,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  updateTableData ({ state }, updateTableData) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + `Planning/${updateTableData.versionid}/Set`,
        withCredentials: false,
        data: updateTableData,
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  updateTableDataLite ({ state }, updateTableData) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + `Planning/${updateTableData.versionid}/Patch`,
        withCredentials: false,
        data: updateTableData,
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  recalcOuterData ({ state }, tabledataid) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Outer/Table/Recalc',
        withCredentials: false,
        data: { tabledataid: tabledataid },
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  recalcVersionOuterData ({ state }, versiondataid) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Planning/Outer/Version/Recalc',
        withCredentials: false,
        data: { versionid: versiondataid },
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  planningGenPdf ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      const payload = {
        plversionid: value.plversion,
        pages: value.pages,
        hidedata: value.hidedata,
      }
      axios({
        method: 'post',
        url: process.env.NODE_ENV === 'development' ? 'http://10.0.0.230:3010/generate' : state.pdfGenURL,
        // url: 'http://10.0.0.230:3010/generate',
        withCredentials: false,
        data: payload,
        responseType: 'arraybuffer',
      }).then((response) => {
        resolve(response)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  setNewContract ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      const contractFilds = Object.keys(value)
      var formData = new FormData()
      contractFilds.forEach((x) => {
        formData.set(x, value[x])
      })
      axios({
        method: 'post',
        url: state.url + 'Contracts/Update',
        withCredentials: false,
        data: formData,
      }).then((response) => {
          resolve(response.data)
        }).catch((e) => {
          reject(e)
      })
    })
  },
  // <<--- contracts
  // доходы  расходы --->>
  // статус заявок для фильтра
  getPostingsState ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'options',
        url: state.url + 'Postings/List',
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  // список статусов
  getPostingsStatusList ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: state.url + 'Postings/Status/List',
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  // получение списка заявок
  getPostingsList ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      const dataPostings = new FormData()
      dataPostings.append('datefrom', state.dateFromListPostings)
      dataPostings.append('datetill', state.dateTillListPostings)
      dataPostings.append('flgviewall', state.flgviewallListPostings)
      axios({
        method: 'post',
        url: state.url + 'Postings/List',
        withCredentials: false,
        data: dataPostings,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  // получение статьи затрат
  getPostingsEntriesList ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      const dataPostings = new FormData()
      dataPostings.append('postingid', value)
      axios({
        method: 'post',
        url: state.url + 'Postings/Entries/List',
        withCredentials: false,
        data: dataPostings,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  // изменение статуса
  getPostingsStatusSet ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      const dataPostings = new FormData()
      dataPostings.append('postingid', value.postingid)
      dataPostings.append('newstatus', value.newstatus)
      if (value.accountid !== null) {
        dataPostings.append('accountid', value.accountid)
      }
      dataPostings.append('reason', value.reason)
      axios({
        method: 'post',
        url: state.url + 'Postings/Status/Set',
        withCredentials: false,
        data: dataPostings,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getPostingsCardShow ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + `Postings/Document/Get?id=${value}`,
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getPostingsFileTypeList ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + 'Postings/File/Type/List',
        withCredentials: false,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  deletePostingsFile ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      const dataPostings = new FormData()
      dataPostings.append('fileid', value)
      axios({
        method: 'post',
        url: state.url + 'Postings/File/Del',
        withCredentials: false,
        data: dataPostings,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  updatePostingsFile ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      const dataPostings = new FormData()
      dataPostings.append('fileid', value.fileid)
      dataPostings.append('filename', value.filename)
      dataPostings.append('fileextension', value.fileextension)
      dataPostings.append('filetype', value.filetype)
      axios({
        method: 'post',
        url: state.url + 'Postings/File/Update',
        withCredentials: false,
        data: dataPostings,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  addPostingsFile ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      const dataPostings = new FormData()
      dataPostings.append('folderid', value.folderid)
      dataPostings.append('filedata', value.filedata)
      axios({
        method: 'post',
        url: state.url + 'Postings/File/Add',
        withCredentials: false,
        data: dataPostings,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  getOpenFilesPostings ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      const formdata = new FormData()
      formdata.append('id', 'value')
      axios({
        method: 'get',
        url: state.url + `Postings/File/Get?id=${value}`,
        withCredentials: false,
        responseType: 'blob',
        data: formdata,
      }).then((response) => {
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  // --->> доходы расходы
  // Vacations --->>
  getFullEmpl ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      // console.log(axios.defaults.headers.common)
      console.log(value.update)
      if (state.VacEmployees.length == 0 || value.update) {
        const formData = new FormData()
        formData.append('Datefrom', value.dateFrom)
        formData.append('Dateto', value.dateTo)
        // require('axios-debug-log')
        axios({
          method: 'post',
          url: state.url + 'TimeSheets/Full/List',
          withCredentials: false,
          data: formData,
        })
          .then((response) => {
            var resp = response.data
            if (Array.isArray(resp)) {
              commit('setVacEmployees', resp)
              resolve(response)
            } else {
              reject(new Error('нет прав'))
            }
          }).catch((e) => {
          console.log('ошибка')
          reject(e)
        })
      } else {
        resolve('ok')
      }
    })
  },
  getVacList ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      // console.log(axios.defaults.headers.common)
      var formData = new FormData()
      formData.set('Datefrom', value.dateFrom)
      formData.set('Dateto', value.dateTo)
      // formData.set('vacempid', value.vacempid)
      axios({
        method: 'post',
        url: state.url + 'TimeSheets/Vacation/List',
        withCredentials: false,
        data: formData,
      })
        .then((response) => {
          // console.log(response)
          var resp = response.data
          if (Array.isArray(resp)) {
            commit('setVacDate', resp)
            resolve(response)
          } else {
            if (response.data.id == -1) {
              dispatch('authLogout')
            }
            reject(new Error('нет прав'))
          }
        }).catch((e) => {
        reject(e)
      })
    })
  },
  AddVacation ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      // console.log(axios.defaults.headers.common)
      var formData = new FormData()
      formData.set('Datefrom', value.dateFrom)
      formData.set('Dateto', value.dateTo)
      formData.set('vacempid', value.vacempid)
      axios({
        method: 'post',
        url: state.url + 'TimeSheets/Vacation/Add',
        withCredentials: false,
        data: formData,
      })
        .then((response) => {
          // console.log(response)
          var resp = response.data
          if (resp.result > 0) {
            var tempFrom = value.dateFrom.split('-')
            var tempTo = value.dateTo.split('-')
            tempFrom = tempFrom[2] + '.' + tempFrom[1] + '.' + tempFrom[0]
            tempTo = tempTo[2] + '.' + tempTo[1] + '.' + tempTo[0]
            commit('addVacation', { resp: resp, date: [tempFrom, tempTo] })
            resolve(response)
          } else {
            reject(response.data)
          }
        }).catch((e) => {
        reject(e)
      })
    })
  },
  DeleteVacation ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      // console.log(axios.defaults.headers.common)
      var formData = new FormData()
      formData.set('vacationid', value.vacationid)
      formData.set('reason', value.reason)
      formData.set('BaseIndex', value.BaseIndex)
      axios({
        method: 'post',
        url: state.url + 'TimeSheets/Vacation/Del',
        withCredentials: false,
        data: formData,
      }).then((response) => {
        if (response.data == 0) {
          resolve(response.data)
          commit('delVacation', { vacationid: value.vacationid })
        } else {
          reject(response.data)
        }
      }).catch((e) => {
        reject(e)
      })
    })
  },
  ChangeVacationStatus ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      console.log(axios.defaults.headers.common)
      var formData = new FormData()
      formData.set('vacationid', value.vacationid)
      formData.set('newstatus', value.newstatus)
      formData.set('reason', value.reason)
      formData.set('BaseIndex', value.BaseIndex)
      axios({
        method: 'post',
        url: state.url + 'TimeSheets/Vacation/Status/Update',
        withCredentials: false,
        data: formData,
      })
        .then((response) => {
          if (response.data == value.vacationid) {
            commit('setStateVacation', { State: value.newstatus, vacationid: value.vacationid })
            resolve(response.data)
          } else {
            reject(response.data)
          }
        }).catch((e) => {
        reject(e)
      })
    })
  },
  clearVacDate ({ commit }, value) {
    commit('clearVacDate', value)
  },
  // --->> Vacations

  // Meetings --->>
  getUserIp ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: process.env.NODE_ENV === 'development' ? 'http://192.168.61.232/GetUserIP' : state.url.replace('api/', '') + 'GetUserIP',
      })
        .then((response) => {
          commit('setUserIp', value)
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  getRoomsList ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + 'Conferences/Rooms/List',
      })
        .then((response) => {
            commit('setRoomsList', value)
            resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  getInfoActiveRooms ({ state, dispatch, commit }, value) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: process.env.NODE_ENV === 'development' ? 'http://localhost:80/api/meetings/room-census' : state.url + 'meetings/room-census',
      })
        .then((response) => {
          commit('setInfoActiveRooms', value)
          resolve(response.data)
        }).catch((e) => {
        reject(e)
      })
    })
  },
  // --->> Meetings

  // store --->>
  updatePlTablesData ({ commit }, value) {
    commit('updatePlTablesData', value)
  },
  updateOnePlTablesData ({ commit }, value) {
    commit('updateOnePlTablesData', value)
  },
  setDataLoading ({ commit }, value) {
    commit('setDataLoading', value)
  },
  setOpenAddChartModal ({ commit }, value) {
    commit('setOpenAddChartModal', value)
  },
  setOpenAddTableModal ({ commit }, value) {
    commit('setOpenAddTableModal', value)
  },
  setInformationAboutTable ({ commit }, value) {
    commit('setInformationAboutTable', value)
  },
  setIsAuthenticated ({ commit }, value) {
    commit('setIsAuthenticated', value)
  },
  setEmpid ({ commit }, value) {
    commit('setEmpid', value)
  },
  setToken ({ commit }, value) {
    commit('setToken', value)
  },
  setAlert ({ commit }, value) {
    commit('setAlert', value)
  },
  setSelectedOrder ({ commit }, value) {
    commit('setSelectedOrder', value)
  },
  setSelectedEmp ({ commit }, value) {
    commit('setSelectedEmp', value)
  },
  setLoading ({ commit }, value) {
    commit('setLoading', value)
  },
  setJitsiScriptLoaded ({ commit }, value) {
    commit('setJitsiScriptLoaded', value)
  },
  setConferenceIsActive ({ commit }, value) {
    commit('setConferenceIsActive', value)
  },
  addticket ({ commit }, value) {
    commit('addticket', value)
  },
  showtickets ({ commit }, value) {
    commit('showtickets', value)
  },
  updateEmpList ({ state, commit }, value) {
    commit('updateEmpList', value)
  },
  setDateTo ({ commit }, value) {
    commit('setDateTo', value)
  },
  setDateFrom ({ commit }, value) {
    commit('setDateFrom', value)
  },
  updateEvents ({ state, commit }, value) {
    commit('updateEvents', value)
  },
  setSnackbar ({ state, commit }, value) {
    commit('setSnackbar', value)
  },
  setSnackbarAutoSum ({ state, commit }, value) {
    commit('setSnackbarAutoSum', value)
  },
  setObjects ({ state, commit }, value) {
    commit('setObjects', value)
  },
  setSelectetObject ({ state, commit }, value) {
    commit('setSelectetObject', value)
  },
  updateEventDetail ({ state, commit }, value) {
    commit('updateEventDetail', value)
  },
  setCalendarStart ({ state, commit }, value) {
    commit('setCalendarStart', value)
  },
  changeInfoDialog ({ state, commit }, value) {
    commit('changeInfoDialog', value)
  },
  setPlTablesChanges ({ state, commit }, value) {
    commit('setPlTablesChanges', value)
  },
  clearPlTablesChanges ({ state, commit }, value) {
    commit('clearPlTablesChanges', value)
  },
  clearFullPlTablesChanges ({ commit }) {
    commit('clearPlTablesData')
    commit('clearFullPlTablesChanges')
  },
  setCssLoad ({ commit }, value) {
    commit('setCssLoad', value)
  },
  getStyle ({ state, commit }) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: state.url + 'Planning/Styles/Css/Get',
        withCredentials: false,
      }).then((response) => {
        commit('setCssStyles', response.data)
        resolve(response.data)
      }).catch((e) => {
        reject(e)
      })
    })
  },
  sendHTML (_, formData) {
    axios({
      method: 'post',
      url: 'http://localhost:3010/create',
      withCredentials: false,
      data: formData,
    }).then((resp) => {
      console.log('resp', resp)
    })
  },
  setCookies (_, payload) {
    cookie.set(payload.cookieName, payload.cookieValue)
  },
  getCookies (_, cookieName) {
    console.log('cookies', cookie.get(cookieName))
    return cookie.get(cookieName)
  },
}
