import Vue from 'vue'
import Router from 'vue-router'
import store from './store/'

Vue.use(Router)
const ifAuthenticated = (to, from, next) => {
  store.dispatch('checkAuth', 'app1').then(() => {
    next()
  }).catch((e) => {
    console.log('e')
    console.log(e)
    store.dispatch('setLastRoute', to)
    store.dispatch('setSnackbar', { state: true, text: e, color: 'warning', direction: 'top center' })
    next('/pages/login')
  })
}
const ifAuthenticatedAPIKEY = (to, from, next) => {
  const { apikey } = to.query
  if (apikey === '686862') {
    store.dispatch('checkAuth', 'app1').then(() => {
      next()
    }).catch((e) => {
      console.log('e')
      console.log(e)
      store.dispatch('setLastRoute', to)
      store.dispatch('setSnackbar', { state: true, text: e, color: 'warning', direction: 'top center' })
      next('/pages/login')
    })
  } else {
    next('/pages/login')
  }
}
const checkHash = (to, from, next) => {
  store.dispatch('checkHashAuth', { id: to.params.id, hash: to.params.hash }).then(() => {
    next('/')
  }).catch((e) => {
    console.log('e')
    console.log(e)
    store.dispatch('setLastRoute', to)
    store.dispatch('setSnackbar', { state: true, text: e, color: 'warning', direction: 'top center' })
    next('/pages/login')
  })
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/pages',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'Авторизация',
          path: 'login',
          component: () => import('@/views/pages/Login'),
        },
        {
          name: 'Авторизация по Hash',
          path: 'loginHash/:id/:hash',
          beforeEnter: checkHash,
        },
      ],
    },
    {
      name: 'Генератор PDF отчетов',
      path: '/reportpdfgen',
      component: () => import('@/views/dashboard/reports/reportPdfGen'),
      beforeEnter: ifAuthenticatedAPIKEY,
    },
    {
      path: '/invoice',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Просмотр счета для ДиР',
          path: 'check/:id',
          component: () => import('@/views/dashboard/InvoceCheck'),
          props: true,
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      beforeEnter: ifAuthenticated,
      beforeLeave: ifAuthenticated,
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: 'Dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        {
          name: 'Телефонный справочник',
          path: 'telefon',
          component: () => import('@/views/dashboard/telefon'),
        },
        {
          name: 'Собрания',
          path: 'meetings',
          component: () => import('@/views/dashboard/meetings'),
        },
        {
          name: 'Инструкции',
          path: 'instructions',
          component: () => import('@/views/dashboard/instructions'),
        },
        {
          name: 'Мои заявки',
          path: 'mytikets',
          component: () => import('@/views/dashboard/tikets'),
        },
        {
          name: 'Мои очереди',
          path: 'myqueues',
          component: () => import('@/views/dashboard/queues'),
        },
        {
          name: ' ',
          path: '',
          component: () => import('@/views/dashboard/DashboardProd'),
        },
        {
          name: 'Сверка СКУД',
          path: 'time',
          component: () => import('@/views/dashboard/time'),
        },
        {
          name: 'catering(табель)',
          path: 'simplefoods/timesheet',
          component: () => import('@/views/dashboard/simplefoods/timesheets'),
        },
        {
          name: 'catering(Сводный)',
          path: 'simplefoods/consolidated',
          component: () => import('@/views/dashboard/simplefoods/timesheetsCons'),
        },
        {
          name: 'catering(Производительность)',
          path: 'simplefoods/performance',
          component: () => import('@/views/dashboard/simplefoods/performance'),
        },
        {
          name: 'catering(Дневная)',
          path: 'simplefoods/perdayperformance',
          component: () => import('@/views/dashboard/simplefoods/PerDayPerform'),
        },
        {
          name: 'Договоры',
          path: 'contracts',
          component: () => import('@/views/dashboard/contracts/contracts'),
        },
        {
          name: 'Доходы и расходы',
          path: 'incomeExpenses',
          component: () => import('@/views/dashboard/incomeExpenses/incomeExpenses'),
        },
        {
          name: 'Планирование и отчетность',
          path: 'planning',
          component: () => import('@/views/dashboard/reports/planning'),
        },
        {
          name: 'Редактор презентации',
          path: 'reportEditor',
          component: () => import('@/views/dashboard/reports/reportEditor'),
        },
        {
          name: 'Конструктор шаблонов',
          path: 'buildertemplateindex',
          component: () => import('@/views/dashboard/reports/builderTemplate/builderTemplateIndex'),
        },
        {
          name: 'Презентации',
          path: 'reportViewer',
          component: () => import('@/views/dashboard/reports/reportViewer'),
        },
        {
          name: 'История изменений',
          path: 'snapshotinfo',
          component: () => import('@/views/dashboard/reports/snapshotinfo'),
        },
        {
          name: 'График отпусков',
          path: 'vacations/vacations',
          component: () => import('@/views/dashboard/vacations/vacations'),
        },
        {
          name: 'График сводный',
          path: 'vacations/vacationsCons',
          component: () => import('@/views/dashboard/vacations/vacationsAll'),
        },
        {
          name: 'Администрирование сайтов',
          path: 'siteadmin/sections',
          component: () => import('@/views/dashboard/siteadmin'),
        },
        {
          name: 'Новости на сайте',
          path: 'siteadmin/news',
          component: () => import('@/views/dashboard/siteadminnews'),
        },
        {
          name: 'Интересное на сайте',
          path: 'siteadmin/interesting',
          component: () => import('@/views/dashboard/siteadminnews'),
        },
        {
          name: 'Акции на сайте',
          path: 'siteadmin/promotion',
          component: () => import('@/views/dashboard/siteadminnews'),
        },
        {
          name: 'Отзывы на сайте',
          path: 'siteadmin/comment',
          component: () => import('@/views/dashboard/siteadminnews'),
        },
        {
          name: 'Направления',
          path: 'siteadmin/directions',
          component: () => import('@/views/dashboard/siteadminnews'),
        },
        {
          name: 'Контактны центр',
          path: 'contacts',
          component: () => import('@/views/dashboard/contacts'),
        },
        // Pages
        {
          name: 'RTL',
          path: 'pages/rtl',
          component: () => import('@/views/dashboard/pages/Rtl'),
        },
        {
          name: 'User Profile',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'Timeline',
          path: 'pages/timeline',
          component: () => import('@/views/dashboard/pages/Timeline'),
        },
        // Components
        {
          name: 'Buttons',
          path: 'components/buttons',
          component: () => import('@/views/dashboard/component/Buttons'),
        },
        {
          name: 'Grid System',
          path: 'components/grid-system',
          component: () => import('@/views/dashboard/component/Grid'),
        },
        {
          name: 'Tabs',
          path: 'components/tabs',
          component: () => import('@/views/dashboard/component/Tabs'),
        },
        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('@/views/dashboard/component/Notifications'),
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('@/views/dashboard/component/Icons'),
        },
        {
          name: 'Typography',
          path: 'components/typography',
          component: () => import('@/views/dashboard/component/Typography'),
        },
        // Forms
        {
          name: 'Regular Forms',
          path: 'forms/regular',
          component: () => import('@/views/dashboard/forms/RegularForms'),
        },
        {
          name: 'Extended Forms',
          path: 'forms/extended',
          component: () => import('@/views/dashboard/forms/ExtendedForms'),
        },
        {
          name: 'Validation Forms',
          path: 'forms/validation',
          component: () => import('@/views/dashboard/forms/ValidationForms'),
        },
        {
          name: 'Wizard',
          path: 'forms/wizard',
          component: () => import('@/views/dashboard/forms/Wizard'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },
        {
          name: 'Extended Tables',
          path: 'tables/extended-tables',
          component: () => import('@/views/dashboard/tables/ExtendedTables'),
        },
        {
          name: 'Data Tabels',
          path: 'tables/data-tables',
          component: () => import('@/views/dashboard/tables/DataTables'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        },
        {
          name: 'Full Screen Map',
          path: 'maps/full-screen-map',
          component: () => import('@/views/dashboard/maps/FullScreenMap'),
        },
        // Root level
        {
          name: 'Widgets',
          path: 'widgets',
          component: () => import('@/views/dashboard/Widgets'),
        },
        {
          name: 'Calendar',
          path: 'calendar',
          component: () => import('@/views/dashboard/Calendar'),
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error'),
        },
      ],
    },
  ],
})

// router.beforeEach((to, from, next) => {
//   if (store.state.preventNext) {
//     if (!window.confirm('Остались не сохраненные данные, всеравно уйти?')) {
//       next(false)
//     } else {
//       next()
//     }
//   } else {
//     next()
//   }
// })
Vue.mixin({
  beforeRouteLeave (to, from, next) {
  if (store.state.preventNext && !window.confirm('Остались несохраненные данные, все равно уйти?')) {
    return
  }

  if (store.state.conferenceIsActive && !window.confirm('Уверены что хотите покинуть конференцию?')) {
    return
  }
  next()
},
})
export default router
