import axios from 'axios/index'
import router from '../router'
import store from '../store'

export default function execute () {
  axios.interceptors.response.use(response => {
    if ((response.data.Code && response.data.Code < 0) || (response.data.result && response.data.result < 0)) {
      const errorText = response.data.Message || response.data.message || 'Ошибка выполнения запроса'
      store.dispatch('setSnackbar', {
        state: true,
        title: null,
        text: errorText,
        color: 'warning',
        direction: 'top center',
      })
      return Promise.reject(new Error(errorText))
    } else {
      return response
    }
  }, error => {
    if (error.response) {
      if (error.response.status == 401 && router.currentRoute.path != '/pages/login') {
        store.dispatch('authLogout')
        store.dispatch('setSnackbar', {
          state: true,
          title: null,
          text: 'Ошибка авторизации',
          color: 'warning',
          direction: 'top center',
        })
        router.push('/pages/login')
      } else {
        let errorMessage = 'Ошибка выполнения запроса'
        let adErrorInfo = ''
        if (error.response.data) {
          adErrorInfo = error.response.data.information ? '\n' + error.response.data.information : null
          errorMessage = error.response.data.Message || error.response.data.message || 'Ошибка выполнения запроса'
        }
        if (adErrorInfo) {
          errorMessage = errorMessage + '!' + adErrorInfo
        }
        console.log('Error Information:', errorMessage)
        console.log('Error Information11111:', errorMessage + '!' + adErrorInfo)
        store.dispatch('setSnackbar', {
          state: true,
          title: null,
          text: errorMessage,
          color: 'warning',
          direction: 'top center',
        })
      }
    } else {
      console.log('Global Error', error)
      store.dispatch('setSnackbar', {
        state: true,
        title: null,
        text: error,
        color: 'warning',
        direction: 'top center',
      })
    }
    return Promise.reject(error)
  })
}
