// =========================================================
// * Vuetify Material Dashboard PRO - v2.0.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store/'
import './plugins/base'
import { VueMaskDirective } from 'v-mask'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import VuetifyDialogPromise from 'vuetify-dialog-promise'
import VueCookies from 'vue-cookies'

import i18n from './i18n'
import interceptor from './plugins/401'
import './sass/overrides.sass'
import { func } from './plugins/globalFunc'

interceptor()

Vue.config.productionTip = false

Vue.prototype.$func = func

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi',
})

Vue.use(VueCookies)

Vue.use(VuetifyDialogPromise, {
  locale: 'ru',
  snackbarX: 'center',
  snackbarY: 'top',
})

Vue.directive('mask', VueMaskDirective)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
