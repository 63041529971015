import getDefaultState from './state'

export default {
  setPreventNext (state, value) {
    state.preventNext = value
  },
  setLastRoute (state, value) {
    state.lastRoute = value
  },
  SET_BAR_IMAGE (state, payload) {
    state.barImage = payload
  },
  SET_DRAWER (state, payload) {
    state.drawer = payload
  },
  SET_SCRIM (state, payload) {
    state.barColor = payload
  },
  resetState (state) {
    const s = getDefaultState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
  setEmpid (state, value) {
    state.empid = value
  },
  setSnackbar (state, value) {
    state.snackbar = value
  },
  setSnackbarAutoSum (state, value) {
    state.snackbarAutoSum = value
  },
  setToken (state, value) {
    state.token = value
  },
  setSelectedOrder (state, value) {
    state.selectedOrder = value
  },
  setObjects (state, value) {
    state.objects = value
  },
  setSelectetObject (state, value) {
    state.selectetObject = value
  },
  setTableStyles (state, value) {
    state.tableStyles = value
  },
  setDataLoading (state, value) {
    state.dataLoading = value
  },
  setOpenAddChartModal (state, value) {
    state.openAddChartModal = value
  },
  setOpenAddTableModal (state, value) {
    state.openAddTableModal = value
  },
  setInformationAboutTable (state, value) {
    console.log(value, 'valie')
    state.informationAboutTable = value
  },
  setObjectListInfo (state, value) {
    const objectsList = {}
    value.forEach((ol) => {
      objectsList[ol.listdataid] = {
        info: ol,
        data: null,
      }
    })
    state.objectsList = objectsList
  },
  setObjectList (state, value) {
    const newObjct = {}
    newObjct[value.id] = state.objectsList[value.id]
    console.log(newObjct, 'newObjct', state.objectsList, 'state.objectsList')
    newObjct[value.id].data = value.data
    state.objectsList = Object.assign({}, state.objectsList, newObjct)
  },
  clearObjectList (state) {
    const tbID = Object.keys(state.objectsList)
    tbID.forEach((o) => {
      state.objectsList[o].data = null
    })
  },
  updateOnePlTablesData (state, value) {
    console.log('mute data', value)
    console.log('mute data1', state.plTablesData[value.tab][value.tableIndex])
    state.plTablesData[value.tab][value.tableIndex] = value.val
    state.plTablesData[value.tab][value.tableIndex] = JSON.parse(JSON.stringify(state.plTablesData[value.tab][value.tableIndex]))
    state.plTablesData = JSON.parse(JSON.stringify(state.plTablesData))
  },
  updatePlTablesData (state, value) {
    state.plTablesData[value.tab] = value.val
    state.plTablesData = JSON.parse(JSON.stringify(state.plTablesData))
  },
  clearPlTablesData (state) {
    state.plTablesData = {}
    state.plTablesData = JSON.parse(JSON.stringify(state.plTablesData))
  },
  setForemanFIO (state, value) {
    state.foremanFIO = value
  },
  showAddCagent (state, value) {
    state.showAddCagent = value
  },
  clearEmployees (state) {
    state.employees = []
  },
  changeInfoDialog (state, value) {
    state.alert = value
  },
  setIsAuthenticated (state, value) {
    state.isAuthenticated = value
  },
  setVacEmployees (state, value) {
    state.VacEmployees = value
    state.VacEmployees.forEach((x) => {
      const keys = Object.keys(x)
      let n = keys.length
      while (n--) {
        const key = keys[n] // "cache" it, for less lookups to the array
        if (key !== key.toLowerCase()) { // might already be in its lower case version
          x[key.toLowerCase()] = x[key] // swap the value to a new lower case key
          delete x[key] // delete the old key
        }
      }
      x.fioempid = x.shortfio + '(' + x.empid + ')'
    })
  },
  setVacDate (state, value) {
    state.VacDate = value
  },
  setSelectedEmp (state, value) {
    state.selectedEmp = value
  },
  addVacation (state, value) {
    state.VacDate.push({
      DateFrom: value.resp.Datefrom,
      DateFromS: value.date[0],
      DateTill: value.resp.Dateto,
      DateTillS: value.date[1],
      DepartmentTitle: state.selectedEmp[0].DepartmentTitle,
      EmpFIO: state.selectedEmp[0].ShortFIO,
      EmpID: state.selectedEmp[0].EmpID,
      CanAccept: 0,
      CanApprove: 0,
      CreatorID: state.empid,
      PostTitle: state.selectedEmp[0].PostTitle,
      StafferVacationID: value.resp.result,
      State: 0,
    })
  },
  delVacation (state, value) {
    const index = state.VacDate.findIndex(x => x.StafferVacationID == value.vacationid)
    state.VacDate.splice(index, 1)
  },
  clearVacDate (state) {
    state.VacDate = []
  },
  setStateVacation (state, value) {
    const index = state.VacDate.findIndex(x => x.StafferVacationID == value.vacationid)
    // console.log(state.VacDate[index].State)
    // console.log(index)
    // console.log(value.State)
    state.VacDate[index].State = value.State
  },
  setRoomsList (state, value) {
    state.roomsDate = value
  },
  setUserIp (state, value) {
    state.userIp = value
  },
  setInfoActiveRooms (state, value) {
    state.infoActiveRooms = value
  },
  setLoading (state, value) {
    state.loading = value
  },
  setJitsiScriptLoaded (state, value) {
    state.jitsiScriptLoaded = value
  },
  setConferenceIsActive (state, value) {
    state.conferenceIsActive = value
  },
  setBuilderTemplatePanelSettingsOpen (state, value) {
    state.builderTemplatePanelSettingsOpen = value
  },
  setEmpList (state, value) {
    state.EmpList = []
    value.forEach((v, i) => {
      v.id = i
      state.empList.push(v)
    })
  },
  setDateFrom (state, value) {
    state.dateFrom = value
  },
  setAlert (state, value) {
    state.alert = value
  },
  addticket (state) {
    state.ticketsType = '?Action=CustomerTicketMessage'
  },
  showtickets (state) {
    state.ticketsType = '?Action=CustomerTicketOverview;Subaction=MyTickets'
  },
  setDateTo (state, value) {
    state.dateTo = value
  },
  updateEmpList (state, value) {
    state.EmpList = value
  },
  setEmployees (state, value) {
    state.employees = []
    value.forEach((x, i) => {
      state.employees.push({ id: i, empid: x.empid, fio: x.fio, mistmatchcount: x.mismatch_count, blank: x.blank })
    })
  },
  setTimeSheetStates (state, value) {
    state.timeSheetsStates = value
  },
  setSPFTimeSheetStates (state, value) {
    state.SPFtimeSheetsStates = value
  },
  setStatesForSPF (state, value) {
    value.forEach((x) => {
      console.log(x)
      state.timeSheetsStates[x.id] = x
    })
  },
  setCssStyles (state, value) {
    state.cssStyles = value
  },
  updateEvents (state, value) {
    state.events = value
  },
  updateEventDetail (state, value) {
    state.empList[value.i].explanatory = value.d
  },
  setCalendarStart (state, value) {
    state.calendarStart = value
  },
  setPlTablesChanges (state, { table, cell, changestate }) {
    if (!state.plTablesChanges[table]) {
      state.plTablesChanges[table] = {}
    }
    state.plTablesChanges[table][cell] = changestate
    state.plTablesChanges = JSON.parse(JSON.stringify(state.plTablesChanges))
  },
  delPlTablesChanges (state, table) {
    state.plTablesChanges[table] = null
  },
  clearPlTablesChanges (state, tables) {
    tables.forEach((table) => {
      if (state.plTablesChanges[table]) state.plTablesChanges[table] = null
    })
  },
  clearFullPlTablesChanges (state) {
    state.plTablesChanges = null
    state.plTablesChanges = {}
  },
  setSelectedCell (state, value) {
    state.plTableState.selectedCell = value
  },
  setChosenCell (state, value) {
    state.plTableState.chosenCell = value
  },
  setTableFilter (state, value) {
    state.tableFilters[value.pltmpltableid] = value.rows
    state.tableFilters = JSON.parse(JSON.stringify(state.tableFilters))
  },
  setTableFilterMainCol (state, value) {
    state.tableFilters.mainCol[value.pltmpltableid] = value.mainCol
    state.tableFilters = JSON.parse(JSON.stringify(state.tableFilters))
  },
  setTableFilterCols (state, value) {
    state.tableFilters.cols[value.pltmpltableid] = value.cols
    state.tableFilters = JSON.parse(JSON.stringify(state.tableFilters))
  },
  setTableFilterRows (state, value) {
    state.tableFilters.rows[value.pltmpltableid] = value.rows
    state.tableFilters = JSON.parse(JSON.stringify(state.tableFilters))
  },
  clearTablesFilter (state, { pltmpltableid, tableid }) {
    state.tableFilters.cols[tableid] = null
    state.tableFilters.rows[pltmpltableid] = null
    state.tableFilters.mainCol[pltmpltableid] = null
    state.tableFilters = JSON.parse(JSON.stringify(state.tableFilters))
  },
  clearAllTablesFilter (state) {
    state.tableFilters.cols = {}
    state.tableFilters.rows = {}
    state.tableFilters.mainCol = {}
    state.tableFilters = JSON.parse(JSON.stringify(state.tableFilters))
  },
  setCssLoad (state, value) {
    state.cssLoad = value
  },
  updateURL (state, newUrl) {
    state.url = newUrl
  },
  setDossiersOfficesFilter (state, newItem) {
    state.dossiersOfficesFilter = newItem
  },
  setDateFromListPostings (state, value) {
    state.dateFromListPostings = value
  },
  setDateTillListPostings (state, value) {
    state.dateTillListPostings = value
  },
  setFlgviewallListPostings (state, value) {
    state.flgviewallListPostings = value
  },
  setCellFormulaData (state, value) {
    state.cellFormula.data = value
  },
  setCellFormulaShow (state, value) {
    state.cellFormula.show = value
  },
}
