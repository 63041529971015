<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
    beforeMount () {
      window.addEventListener('beforeunload', this.preventNav)
      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('beforeunload', this.preventNav)
      })
    },
    methods: {
      preventNav (event) {
        if (!this.$store.state.preventNext) return
        event.preventDefault()
        event.returnValue = ''
      },
    },
  }
</script>

<style>
.v-navigation-drawer__content::-webkit-scrollbar, .pages-side-bar-inner::-webkit-scrollbar {
  width: .35em;
  background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar {
  width: .45em;
  background-color: white;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 3px 6px rgba(140, 54, 54, 0.1);
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 3px 6px rgba(208, 153, 153, 0.8);
}
</style>
